import React, { useEffect, useState, createRef } from 'react';
import apiClient from 'apiClient';
import moment from 'moment';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Table from 'ui-component/table';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from 'ui-component/datepicker';

import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import BondDialog from 'ui-component/dialog/bond';
import { Navigate } from 'react-router-dom';
import DeleteDialog from 'ui-component/dialog/delete';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={true}
            valueIsNumericString
            prefix="E"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function Bonds() {
    const tableRef = createRef();
    const [client, setClient] = useState(null);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [paymentPeriod, setPaymentPeriod] = useState(null);
    const [presentValue, setPresentValue] = useState('0.0');
    const [futureValue, setFutureValue] = useState('0.0');
    const [interest, setInterest] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [clients, setClients] = useState([]);
    const [clientsRes, setClientsRes] = useState([]);
    const [paymentPeriods, setPaymentPeriods] = useState(['Quarterly', 'Bi-Annually', 'Annually', 'Maturity']);
    const [columns, setColumns] = useState([]);
    const [operation, setOperation] = useState('Add');
    const [uuid, setUuid] = useState('');
    var clientsList = [];
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleChangePresentValue = (event) => {
        setPresentValue(event.target.value);
    };

    const handleChangeFutureValue = (event) => {
        setFutureValue(event.target.value);
    };

    const handleClickSave = () => {
        setOperation('Add');
        setOpen(true);
    };

    const handleClickEdit = (rowData) => {
        setUuid(rowData.uuid);
        setOperation('Edit');
        setClient(rowData.client_id);
        setCode(rowData.code);
        setName(rowData.name);
        setPaymentPeriod(rowData.payment_periods);
        setPresentValue(rowData.present_value);
        setFutureValue(rowData.future_value);
        setInterest(rowData.interest);
        setStartDate(rowData.start_date);
        setEndDate(rowData.end_date);
        setOpen(true);
    };

    const handleClickDelete = (rowData) => {
        setOperation('Delete');
        setUuid(rowData.uuid);
        setName(rowData.name);
        // Add delete confirmation
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDelete = () => {
        setDeleteOpen(false);
    };

    const handleSave = () => {
        let newData = {
            code: code,
            name: name,
            client_id: client,
            start_date: startDate,
            end_date: endDate,
            payment_periods: paymentPeriod,
            present_value: presentValue,
            interest: interest,
            future_value: futureValue
        };
        if (operation === 'Add') {
            apiClient
                .post('/api/bonds', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                })
                .catch((e) => {});
        } else if (operation === 'Edit') {
            apiClient
                .put('/api/bonds/' + uuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                })
                .catch((e) => {});
        } else if (operation === 'Delete') {
            apiClient
                .delete('/api/bonds/' + uuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleCloseDelete();
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    useEffect(() => {
        apiClient
            .get(`/api/clients`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setClientsRes(res.data.data);
                res.data.data.map((item) => {
                    clientsList[item.id] = item.name;
                    return null;
                });
                setClients(clientsList);
            })
            .catch((e) => {
                console.log(e);
            });

        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        setColumns([
            {
                title: 'Code',
                field: 'code'
            },
            {
                title: 'Bond Name',
                field: 'name'
            },
            {
                title: 'Client',
                field: 'client_id',
                lookup: clients,
                initialEditValue: null
            },
            {
                title: 'Start Date',
                field: 'start_date',
                editComponent: (props) => <CustomDatePicker value={props.value} onChange={props.onChange} />,
                render: (rowData) => <p>{moment(rowData.start_date).format('DD MMM YYYY')}</p>
            },
            {
                title: 'End Date',
                field: 'end_date',
                editComponent: (props) => <CustomDatePicker value={props.value} onChange={props.onChange} />,
                render: (rowData) => <p>{moment(rowData.end_date).format('DD MMM YYYY')}</p>
            },
            {
                title: 'Payment Periods',
                field: 'payment_periods',
                lookup: { Quarterly: 'Quarterly', 'Bi-Annually': 'Bi-Annually', Annually: 'Annually', Maturity: 'Maturity' }
            },
            {
                title: 'Present Value',
                field: 'present_value',
                type: 'numeric',
                align: 'left',
                render: (rowData) => <NumericFormat value={rowData.present_value} displayType="text" thousandSeparator={true} prefix="E" />
            },
            {
                title: 'Interest (%)',
                field: 'interest',
                type: 'numeric'
            },
            {
                title: 'Future Value',
                field: 'future_value',
                type: 'numeric',
                align: 'left',
                render: (rowData) => <NumericFormat value={rowData.future_value} displayType="text" thousandSeparator={true} prefix="E" />
            },
            {
                title: 'Month Created',
                field: 'created_at',
                type: 'date',
                editable: 'never',
                render: (rowData) => <p>{moment(rowData.created_at).format('MMMM')}</p>
            }
        ]);
    }, [clients]);

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <Table
                    title="Bonds"
                    columns={columns}
                    api="/api/bonds"
                    actionsColumnIndex={0}
                    filtering={true}
                    tableRef={tableRef}
                    customActions={true}
                    actions={[
                        {
                            icon: AddBox,
                            tooltip: 'Add Bond',
                            isFreeAction: true,
                            onClick: (event) => handleClickSave()
                        },
                        {
                            icon: Edit,
                            tooltip: 'Edit Bond',
                            onClick: (event, rowData) => {
                                handleClickEdit(rowData);
                            }
                        },
                        {
                            icon: DeleteOutline,
                            tooltip: 'Delete Bond',
                            onClick: (event, rowData) => {
                                handleClickDelete(rowData);
                            }
                        }
                    ]}
                />

                <BondDialog
                    open={open}
                    handleClose={handleClose}
                    code={code}
                    setCode={setCode}
                    name={name}
                    setName={setName}
                    client={client}
                    setClient={setClient}
                    clientsRes={clientsRes}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    paymentPeriod={paymentPeriod}
                    paymentPeriods={paymentPeriods}
                    setPaymentPeriod={setPaymentPeriod}
                    presentValue={presentValue}
                    handleChangePresentValue={handleChangePresentValue}
                    interest={interest}
                    setInterest={setInterest}
                    futureValue={futureValue}
                    handleChangeFutureValue={handleChangeFutureValue}
                    handleSave={handleSave}
                />

                <DeleteDialog open={deleteOpen} handleClose={handleCloseDelete} handleSave={handleSave} label={name} />
            </MainCard>
        </>
    );
}
