import React, { useEffect, useState } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Table from 'ui-component/table';
import { Navigate } from 'react-router-dom';

export default function Users() {
    const columns = [
        {
            title: 'Name',
            field: 'name'
        },
        {
            title: 'Email',
            field: 'email'
        },
        {
            title: 'Type',
            field: 'type'
        },
        {
            title: 'Password',
            field: 'password',
            render: (rowData) => (
                <p>
                    <i>Hidden</i>
                </p>
            )
        },
        {
            title: 'Confirm Password',
            field: 'password_confirmation',
            render: (rowData) => (
                <p>
                    <i>Hidden</i>
                </p>
            )
        }
    ];
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <Table title="Users" columns={columns} api="/api/users" actionsColumnIndex={0} />
            </MainCard>
        </>
    );
}
