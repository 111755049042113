import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://alphszapi.bpdteam.com',
    // baseURL: 'http://104.248.54.26',
    // baseURL: 'http://127.0.0.1:8000',
    withCredentials: true
});

export default apiClient;
