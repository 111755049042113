import apiClient from 'apiClient';
import React, { useEffect, useState, createRef } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Table from 'ui-component/table';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import TransactionDialog from 'ui-component/dialog/transaction';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={true}
            valueIsNumericString
            prefix="E"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function Transactions() {
    const tableRef = createRef();
    const [code, setCode] = useState('');
    const [date, setDate] = useState(null);
    const [description, setDescription] = useState('');
    const [fund_id, setFundID] = useState(0);
    const [opening_balance, setOpeningBalance] = useState(0);
    const [transaction_type_id, setTransactionTypeId] = useState(0);
    const [amount, setAmount] = useState(0);
    const [closing_balance, setClosingBalance] = useState(0);
    const [fund_type_id, setFundTypeId] = useState(0);
    const [columns, setColumns] = useState([]);
    const [transactiontypes, setTransactiontypes] = useState([]);
    const [fundtypes, setFundtypes] = useState([]);
    const [funds, setFunds] = useState([]);
    const [transactiontypesres, setTransactiontypesRes] = useState([]);
    const [fundtypesres, setFundtypesRes] = useState([]);
    const [fundsres, setFundsRes] = useState([]);
    var fundsList = [];
    var transactiontypesList = [];
    var fundtypesList = [];
    const [operation, setOperation] = useState('Add');
    const [uuid, setUuid] = useState('');
    const [open, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleChangeOpeningBalance = (event) => {
        setOpeningBalance(event.target.value);
    };

    const handleChangeClosingBalance = (event) => {
        setClosingBalance(event.target.value);
    };

    const handleChangeAmount = (event) => {
        setAmount(event.target.value);
    };

    const handleClickSave = () => {
        setOperation('Add');
        setOpen(true);
    };

    const handleClickEdit = (rowData) => {
        setUuid(rowData.uuid);
        setOperation('Edit');
        setDate(rowData.date);
        setCode(rowData.code);
        setDescription(rowData.description);
        setFundID(rowData.fund_id);
        setOpeningBalance(rowData.opening_balance);
        setTransactionTypeId(rowData.transaction_type_id);
        setAmount(rowData.amount);
        setClosingBalance(rowData.closing_balance);
        setFundTypeId(rowData.fund_type_id);
        setOpen(true);
    };

    const handleClickDelete = () => {
        setOperation('Delete');
        // Add delete confirmation
        // setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        let newData = {
            code: code,
            description: description,
            date: date,
            fund_id: fund_id,
            opening_balance: opening_balance,
            transaction_type_id: transaction_type_id,
            amount: amount,
            closing_balance: closing_balance,
            fund_type_id: fund_type_id
        };
        if (operation === 'Add') {
            apiClient
                .post('/api/transactions', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                })
                .catch((e) => {});
        } else if (operation === 'Edit') {
            apiClient
                .put('/api/transactions/' + uuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                })
                .catch((e) => {});
        } else if (operation === 'Delete') {
            apiClient
                .delete('/api/transactions/' + uuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };

    useEffect(() => {
        apiClient
            .get(`/api/funds`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setFundsRes([...res.data.data.unitTrusts, ...res.data.data.bonds, ...res.data.data.cashAccounts]);
                [...res.data.data.unitTrusts, ...res.data.data.bonds, ...res.data.data.cashAccounts].map((item) => {
                    fundsList[item.uuid] = item.name;
                    return null;
                });
                setFunds(fundsList);
            })
            .catch((e) => {
                console.log(e);
            });
        apiClient
            .get(`/api/transactiontypes`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setTransactiontypesRes(res.data.data);
                res.data.data.map((item) => {
                    transactiontypesList[item.id] = item.name;
                    return null;
                });
                setTransactiontypes(transactiontypesList);
            })
            .catch((e) => {
                console.log(e);
            });

        apiClient
            .get(`/api/fundtypes`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setFundtypesRes(res.data.data);
                res.data.data.map((item) => {
                    fundtypesList[item.id] = item.name;
                    return null;
                });
                setFundtypes(fundtypesList);
            })
            .catch((e) => {
                console.log(e);
            });

        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        setColumns([
            {
                title: 'Code',
                field: 'code',
                hidden: true
            },
            {
                title: 'Description',
                field: 'description',
                hidden: true
            },
            {
                title: 'Fund',
                field: 'fund_id',
                lookup: funds,
                initialEditValue: null
            },
            {
                title: 'Opening Balance',
                field: 'opening_balance',
                type: 'numeric',
                align: 'left',
                render: (rowData) => (
                    <NumericFormat value={rowData.opening_balance} displayType="text" thousandSeparator={true} prefix="E" />
                )
            },
            {
                title: 'Transaction Type',
                field: 'transaction_type_id',
                lookup: transactiontypes,
                initialEditValue: null
            },
            {
                title: 'Date',
                field: 'date',
                type: 'datetime',
                render: (rowData) => <p>{moment(rowData.date).format('DD MMM YYYY')}</p>
            },
            {
                title: 'Amount',
                field: 'amount',
                type: 'numeric',
                align: 'left',
                render: (rowData) => <NumericFormat value={rowData.amount} displayType="text" thousandSeparator={true} prefix="E" />
            },
            {
                title: 'Closing Balance',
                field: 'closing_balance',
                type: 'numeric',
                align: 'left',
                render: (rowData) => (
                    <NumericFormat value={rowData.closing_balance} displayType="text" thousandSeparator={true} prefix="E" />
                )
            },
            {
                title: 'Fund Type',
                field: 'fund_type_id',
                lookup: fundtypes,
                initialEditValue: null
            }
        ]);
    }, [transactiontypes, fundtypes]);

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <Table
                    title="Transaction"
                    columns={columns}
                    api="/api/transactions"
                    actionsColumnIndex={0}
                    filtering={true}
                    tableRef={tableRef}
                    customActions={true}
                    actions={[
                        {
                            icon: AddBox,
                            tooltip: 'Add Transaction',
                            isFreeAction: true,
                            onClick: (event) => handleClickSave()
                        },
                        {
                            icon: Edit,
                            tooltip: 'Edit Transaction',
                            onClick: (event, rowData) => {
                                handleClickEdit(rowData);
                            }
                        },
                        {
                            icon: DeleteOutline,
                            tooltip: 'Delete Transaction',
                            onClick: (event, rowData) => {
                                handleClickDelete(rowData);
                            }
                        }
                    ]}
                />

                <TransactionDialog
                    open={open}
                    handleClose={handleClose}
                    code={code}
                    setCode={setCode}
                    fund_id={fund_id}
                    setFundID={setFundID}
                    fundsres={fundsres}
                    fund_type_id={fund_type_id}
                    setFundTypeId={setFundTypeId}
                    date={date}
                    setDate={setDate}
                    fundtypesres={fundtypesres}
                    transaction_type_id={transaction_type_id}
                    setTransactionTypeId={setTransactionTypeId}
                    transactiontypesres={transactiontypesres}
                    description={description}
                    setDescription={setDescription}
                    opening_balance={opening_balance}
                    handleChangeOpeningBalance={handleChangeOpeningBalance}
                    amount={amount}
                    handleChangeAmount={handleChangeAmount}
                    closing_balance={closing_balance}
                    handleChangeClosingBalance={handleChangeClosingBalance}
                    handleSave={handleSave}
                />
            </MainCard>
        </>
    );
}
