import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React, { useEffect, useState, createRef } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Table from 'ui-component/table';
import apiClient from '../../../apiClient';

import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import CashAccountDialog from 'ui-component/dialog/cashAccount';

export default function Statement() {
    const tableRef = createRef();
    const [clients, setClients] = useState([]);
    const [columns, setColumns] = useState([]);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [interest, setInterest] = useState(0);
    const [open, setOpen] = useState(false);
    const [operation, setOperation] = useState('Add');
    const [client, setClient] = useState(null);
    const [clientsRes, setClientsRes] = useState([]);
    const [uuid, setUuid] = useState('');
    var clientsList = [];
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClickSave = () => {
        setOperation('Add');
        setOpen(true);
    };

    const handleClickEdit = (rowData) => {
        setOperation('Edit');
        setUuid(rowData.uuid);
        setClient(rowData.client_id);
        setCode(rowData.code);
        setName(rowData.name);
        setInterest(rowData.interest);
        setOpen(true);
    };

    const handleClickDelete = () => {
        setOperation('Delete');
        // Add delete confirmation
        // setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        let newData = {
            code: code,
            name: name,
            interest: interest,
            client_id: client
        };
        if (operation === 'Add') {
            apiClient
                .post('/api/cashaccounts', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                })
                .catch((e) => {});
        } else if (operation === 'Edit') {
            apiClient
                .put('/api/cashaccounts/' + uuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                    handleClose();
                })
                .catch((e) => {});
        } else if (operation === 'Delete') {
            apiClient
                .delete('/api/cashaccounts/' + uuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    tableRef.current;
                    tableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };

    useEffect(() => {
        apiClient
            .get(`/api/clients`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setClientsRes(res.data.data);
                res.data.data.map((item) => {
                    clientsList[item.id] = item.name;
                    return null;
                });
                setClients(clientsList);
            })
            .catch((e) => {
                console.log(e);
            });

        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        setColumns([
            {
                title: 'Client',
                field: 'client_id',
                lookup: clients,
                initialEditValue: null
            },
            {
                title: 'Total Investment',
                field: 'total_investment',
                type: 'numeric'
            },
            {
                title: 'Sanlam Fee',
                field: 'sanlam_fee',
                type: 'numeric'
            }
        ]);
    }, [clients]);

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <Table
                    title="Payments"
                    columns={columns}
                    api="/api/payments"
                    tableRef={tableRef}
                    customActions={true}
                    actions={[
                        {
                            icon: AddBox,
                            tooltip: 'Add Cash Account',
                            isFreeAction: true,
                            onClick: (event) => handleClickSave()
                        },
                        {
                            icon: Edit,
                            tooltip: 'Edit Cash Account',
                            onClick: (event, rowData) => {
                                handleClickEdit(rowData);
                            }
                        },
                        {
                            icon: DeleteOutline,
                            tooltip: 'Delete Cash Account',
                            onClick: (event, rowData) => {
                                handleClickDelete(rowData);
                            }
                        }
                    ]}
                    actionsColumnIndex={0}
                />

                <CashAccountDialog
                    open={open}
                    handleClose={handleClose}
                    code={code}
                    setCode={setCode}
                    name={name}
                    setName={setName}
                    client={client}
                    setClient={setClient}
                    clientsRes={clientsRes}
                    interest={interest}
                    setInterest={setInterest}
                    handleSave={handleSave}
                />
            </MainCard>
        </>
    );
}
