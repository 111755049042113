import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Hidden
} from '@mui/material';
import React from 'react';
import CustomDatePicker from 'ui-component/datepicker';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={true}
            valueIsNumericString
            prefix="E"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function TransactionDialog(props) {
    function handleChangeAmount(e) {
        props.handleChangeAmount(e);
        // console.log(props.opening_balance);
        // console.log(e.target.value);
        props.handleChangeClosingBalance(parseFloat(props.opening_balance) + parseFloat(e.target.value));
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Transaction</DialogTitle>
            <DialogContent>
                <DialogContentText>Fill in the form to add/edit the transaction</DialogContentText>
                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                    {!props.minimal ? (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    value={props.code}
                                    onChange={(e) => props.setCode(e.target.value)}
                                    margin="dense"
                                    id="code"
                                    label="Code"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {/* <TextField margin="dense" id="client" label="Client" type="text" fullWidth variant="outlined" /> */}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Fund</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.fund_id}
                                        label="Fund"
                                        onChange={(e) => props.setFundID(e.target.value)}
                                    >
                                        {props.fundsres.map((item) => {
                                            return (
                                                <MenuItem key={`fund-${item.code}`} value={item.uuid}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Fund Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.fund_type_id}
                                        label="Fund Type"
                                        onChange={(e) => {
                                            props.setFundTypeId(e.target.value);
                                            props.setDescription(e.target.name);
                                        }}
                                    >
                                        {props.fundtypesres.map((item) => {
                                            return (
                                                <MenuItem key={`fundtypesres-${item.code}`} value={item.id} name={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    ) : null}

                    <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.transaction_type_id}
                                label="Transaction Type"
                                onChange={(e) => props.setTransactionTypeId(e.target.value)}
                            >
                                {props.transactiontypesres.map((item) => {
                                    return (
                                        <MenuItem key={`transactiontypes-${item.code}`} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomDatePicker label="Date" margin="dense" value={props.date} onChange={props.setDate} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                            value={props.description}
                            onChange={(e) => props.setDescription(e.target.value)}
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid> */}
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            value={props.opening_balance}
                            onChange={props.handleChangeOpeningBalance}
                            name="opening_balance"
                            margin="dense"
                            id="name"
                            label="Opening Balance"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            value={props.amount}
                            onChange={props.handleChangeAmount}
                            name="amount"
                            margin="dense"
                            id="name"
                            label="Amount"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            value={props.closing_balance}
                            onChange={props.handleChangeClosingBalance}
                            name="closing_balance"
                            margin="dense"
                            id="name"
                            label="Closing Balance"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button
                    onClick={() => {
                        props.setCode(`T-${Date.now()}`);
                        props.handleSave();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
