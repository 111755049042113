/* eslint-disable no-unused-vars */
import React from 'react';
import logo from '../../assets/images/logo.png';
import logo1 from '../../assets/images/icon1.png';
import source from '../../assets/fonts/Roboto/Roboto-Bold.ttf';
import { Document, Page, Text, Image, View, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { TableHeader, DataTableCell, Table, TableBody, TableCell } from '@david.kucsai/react-pdf-table';
import moment from 'moment';
import { useEffect } from 'react';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

Font.register({
    family: 'Roboto',
    src: source
});

function ccyFormat(num) {
    var value = parseFloat(num);
    return value.toLocaleString(undefined, { minimumFractionDigits: 2 });
}

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        padding: '50px',
        paddingBottom: '70px'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    image: {
        width: '140px',
        height: '40px',
        marginBottom: '10px'
    },
    imageFooter: {
        width: '25px',
        height: '40px',
        position: 'absolute',
        bottom: 20,
        left: 480,
        right: 0
    },
    normalText: {
        fontSize: '8px',
        color: '#000000'
    },
    smallText: {
        fontSize: '5px',
        color: '#000000'
    },
    titleText: {
        fontSize: '24px',
        color: '#393184',
        marginTop: '10px',
        textAlign: 'center'
    },
    headingText: {
        fontSize: '14px',
        textAlign: 'center'
    },
    subHeadingText: {
        fontSize: '10px',
        textAlign: 'center',
        marginBottom: '5px'
    },
    subHeadingTextBlue: {
        fontSize: '10px',
        color: '#393184',
        marginBottom: '5px'
    },
    subHeadingTextLeft: {
        fontSize: '10px',
        marginBottom: '5px'
    },
    bold: {
        fontFamily: 'Roboto'
    },
    tableHeader: {
        fontSize: '8px',
        backgroundColor: '#393184',
        color: '#ffffff',
        padding: '5px',
        fontFamily: 'Roboto',
        border: '0.8px solid #e3e1e1',
        margin: '-1px'
    },
    tableBody: {
        backgroundColor: '#ffffff',
        fontSize: '8px',
        padding: '5px',
        border: '0.8px solid #e3e1e1',
        margin: '-1px'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    },
    footer: {
        position: 'absolute',
        fontSize: 10,
        bottom: 45,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    },
    link: {
        color: '#393184',
        textDecoration: 'underline',
        paddingBottom: 3
    }
});

export default function StatementGenerator(props) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={styles.section}>
                            <Image src={logo} alt="Sanlam" style={styles.image} />
                            <Text style={styles.normalText}>3rd Floor, Fincorp Building</Text>
                            <Text style={styles.normalText}>Cnr Gwamile & Dabede Street , Mbabane</Text>
                            <Text style={styles.normalText}>P. O. Box 8392, Mbabane, H100, Swaziland</Text>
                            <Text style={styles.normalText}>
                                Tel:{' '}
                                <Link src="tel:+26824041338" style={styles.link}>
                                    +268 2404 1338
                                </Link>
                            </Text>
                            <Text style={styles.normalText}>Fax: +268 2404 7248</Text>
                            <Text style={styles.normalText}>
                                E-mail:{' '}
                                <Link src="mailto:info@alphsz.com" style={styles.link}>
                                    info@alphsz.com
                                </Link>
                            </Text>
                            <Text style={styles.normalText}>
                                Web:{' '}
                                <Link src="https://www.alphsz.com" style={styles.link}>
                                    www.alphsz.com
                                </Link>
                            </Text>
                        </View>
                        <View>
                            <Text style={[styles.titleText, { marginRight: '15px', marginTop: '30px' }]}>Investments</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={styles.section}>
                            <Text style={[styles.normalText, { textAlign: 'right' }]}>
                                Statement Date: {moment(new Date()).format('DD MMMM YYYY')}
                            </Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={styles.section}>
                            <Text style={[styles.headingText, styles.bold]}>Investor Statement</Text>
                            <Text style={[styles.subHeadingText, styles.bold]}>
                                For the period {moment(props.startDate).format('DD MMMM YYYY')} to{' '}
                                {moment(props.endDate).subtract(1, 'days').format('DD MMMM YYYY')}
                            </Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={styles.section}>
                            <Text style={[styles.subHeadingTextBlue, styles.bold]}>Investment summary</Text>
                            <Text style={[styles.subHeadingTextLeft, styles.bold]}>
                                Investor name: {props.rowData ? props.rowData.name : '-'}
                            </Text>
                            <Text style={[styles.subHeadingTextLeft, styles.bold]}>
                                Investor code: {props.rowData ? props.rowData.code : '-'}
                            </Text>
                            <Table data={props.data}>
                                <TableHeader textAlign={'center'}>
                                    <TableCell style={styles.tableHeader} weighting={0.2}>
                                        Equity number
                                    </TableCell>
                                    <TableCell style={styles.tableHeader} weighting={0.4}>
                                        Equity Name
                                    </TableCell>
                                    <TableCell style={styles.tableHeader} weighting={0.2}>
                                        Start date market value (E)
                                    </TableCell>
                                    <TableCell style={styles.tableHeader} weighting={0.2}>
                                        End date market value (Net of fees) (E)
                                    </TableCell>
                                </TableHeader>
                                <TableBody>
                                    <DataTableCell style={styles.tableBody} weighting={0.2} getContent={(r) => r.code} />
                                    <DataTableCell style={styles.tableBody} weighting={0.4} getContent={(r) => r.name} />
                                    <DataTableCell
                                        style={[styles.tableBody, styles.center, { textAlign: 'right' }]}
                                        weighting={0.2}
                                        getContent={(r) => ccyFormat(r.start_value)}
                                    />
                                    <DataTableCell
                                        style={[styles.tableBody, styles.center, { textAlign: 'right' }]}
                                        weighting={0.2}
                                        getContent={(r) => ccyFormat(r.end_value)}
                                    />
                                </TableBody>
                            </Table>
                            <View style={{ marginTop: '-1px' }}>
                                <Table data={[{ number: 1, name: 'Test 1', startValue: 3, endValue: 900 }]}>
                                    <TableBody>
                                        <TableCell style={[styles.tableBody, styles.bold]} weighting={0.2}>
                                            Total
                                        </TableCell>
                                        <TableCell style={styles.tableBody} weighting={0.4}></TableCell>
                                        <TableCell style={[styles.tableBody, styles.bold, { textAlign: 'right' }]} weighting={0.2}>
                                            {ccyFormat(props.totalStartValue)}
                                        </TableCell>
                                        <TableCell style={[styles.tableBody, styles.bold, { textAlign: 'right' }]} weighting={0.2}>
                                            {ccyFormat(props.totalEndValue)}
                                        </TableCell>
                                    </TableBody>
                                </Table>
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={styles.section}>
                            <Text style={[styles.subHeadingTextBlue, styles.bold]}>Movement for the period</Text>
                            <Table data={props.transactionData}>
                                <TableHeader>
                                    <TableCell style={[styles.tableHeader, styles.bold, { textAlign: 'right' }]} weighting={0.2}>
                                        Date
                                    </TableCell>
                                    <TableCell style={[styles.tableHeader, styles.bold, { textAlign: 'center' }]} weighting={0.5}>
                                        Transaction Period
                                    </TableCell>
                                    <TableCell style={[styles.tableHeader, styles.bold, { textAlign: 'right' }]} weighting={0.3}>
                                        Total (E)
                                    </TableCell>
                                </TableHeader>
                                <TableBody>
                                    <DataTableCell style={[styles.tableBody, styles.bold]} weighting={0.2} getContent={(r) => r.date} />
                                    <DataTableCell
                                        style={[styles.tableBody, styles.bold, { textAlign: 'center' }]}
                                        weighting={0.5}
                                        getContent={(r) => r.period}
                                    />
                                    <DataTableCell
                                        style={[styles.tableBody, styles.bold, { textAlign: 'right' }]}
                                        weighting={0.3}
                                        getContent={(r) => (r.period === 'Disinvestment' ? `(${ccyFormat(r.total)})` : ccyFormat(r.total))}
                                    />
                                </TableBody>
                            </Table>
                        </View>
                    </View>
                </View>
                <View style={styles.footer}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'column', marginLeft: '70px' }}>
                            <Text style={[styles.bold, styles.normalText]}>Alphsz Limited</Text>
                            <Text style={[styles.normalText, { marginTop: '5px' }]}>3rd floor, Fincorp Building,</Text>
                            <Text style={styles.normalText}>Cnr Gwamile & Dabede Street,</Text>
                            <Text style={styles.normalText}>Mbabane, Eswatini</Text>
                            <Text style={[styles.smallText, { marginTop: '10px' }]}>Alphsz Limited Certificate 1160 of 2010</Text>
                            <Text style={styles.smallText}>
                                Directors: Zacheus Nkosi (Chairman), Sharon De Sousa, Thandile Nxumalo, Andre van Eden, Sifiso Nxumalo
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginLeft: '70px' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={[styles.normalText, styles.bold, { marginRight: '5px', color: '#393184' }]}>T</Text>
                                <Text style={styles.normalText}>+268 2404 1338</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={[styles.normalText, styles.bold, { marginRight: '5px', color: '#393184' }]}>F</Text>
                                <Text style={styles.normalText}>+268 2404 7248</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={[styles.normalText, styles.bold, { marginRight: '5px', color: '#393184' }]}>E</Text>
                                <Text style={styles.normalText}>info@alphsz.com</Text>
                            </View>
                            <Text style={[styles.subHeadingTextBlue, styles.bold, { marginTop: '25px' }]}>www.alphsz.com</Text>
                        </View>
                        <Image src={logo1} alt="Sanlam" style={styles.imageFooter} />
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
            </Page>
        </Document>
    );
}
