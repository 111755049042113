import React, { useEffect, useState } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Table from 'ui-component/table';
import apiClient from '../../../apiClient';
import { Navigate } from 'react-router-dom';

export default function UnitTrusts() {
    const [clients, setClients] = useState([]);
    const [columns, setColumns] = useState([]);
    var clientsList = [];
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        apiClient
            .get(`/api/clients`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                // setClients(res.data.data);
                res.data.data.map((item) => {
                    clientsList[item.id] = item.name;
                    return null;
                });
                setClients(clientsList);
            })
            .catch((e) => {
                console.log(e);
            });

        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        setColumns([
            {
                title: 'Code',
                field: 'code'
            },
            {
                title: 'Unit Trust Name',
                field: 'name'
            },
            {
                title: 'Fee',
                field: 'fee',
                render: (rowData) => <p>{rowData.fee} %</p>
            },
            {
                title: 'Client',
                field: 'client_id',
                lookup: clients,
                initialEditValue: null
            }
        ]);
    }, [clients]);

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <Table title="Unit Trusts" columns={columns} api="/api/unittrusts" actionsColumnIndex={0} />
            </MainCard>
        </>
    );
}
