import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Users from 'views/pages/users';
import Clients from 'views/pages/clients';
import Payments from 'views/pages/payments';
import Statements from 'views/pages/statements';
import UnitTrusts from 'views/pages/unit-trusts';
import Bonds from 'views/pages/bonds';
import Cash from 'views/pages/cash';
import FundTypes from 'views/pages/fund-types';
import TransactionTypes from 'views/pages/transaction-types';
import Transactions from 'views/pages/transactions';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'users',
            element: <Users />
        },
        {
            path: 'clients',
            element: <Clients />
        },
        {
            path: 'unit-trusts',
            element: <UnitTrusts />
        },
        {
            path: 'bonds',
            element: <Bonds />
        },
        {
            path: 'cash',
            element: <Cash />
        },
        {
            path: 'payments',
            element: <Payments />
        },
        {
            path: 'statements',
            element: <Statements />
        },
        {
            path: 'transactions',
            element: <Transactions />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'fund-types',
            element: <FundTypes />
        },
        {
            path: 'transaction-types',
            element: <TransactionTypes />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
