import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import moment from 'moment';

const CustomDatePicker = (props) => {
    const [value, setValue] = React.useState(props.value);

    useEffect(() => {
        setValue(new Date(props.value));
        // console.log('>>>>>>>>>>', new Date(props.value));
    }, []);

    return (
        <TextField
            id="date"
            type="date"
            label={props.label}
            value={value}
            fullWidth
            margin="dense"
            InputLabelProps={{
                shrink: true
            }}
            onChange={(e) => {
                props.onChange(e.target.value);
                setValue(e.target.value);
            }}
        />
    );
};

export default CustomDatePicker;
