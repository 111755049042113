import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import CustomDatePicker from 'ui-component/datepicker';
import PropTypes from 'prop-types';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={true}
            valueIsNumericString
            prefix="E"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function BondDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Bond</DialogTitle>
            <DialogContent>
                <DialogContentText>Fill in the form to add/edit the bond</DialogContentText>
                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            value={props.code}
                            onChange={(e) => props.setCode(e.target.value)}
                            margin="dense"
                            id="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.name}
                            onChange={(e) => props.setName(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Bond Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField margin="dense" id="client" label="Client" type="text" fullWidth variant="outlined" /> */}
                        <FormControl fullWidth disabled={props.disableClient ? props.disableClient : false}>
                            <InputLabel id="demo-simple-select-label">Client</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.client}
                                label="Client"
                                onChange={(e) => props.setClient(e.target.value)}
                            >
                                {props.clientsRes.map((item) => {
                                    return (
                                        <MenuItem key={`client-${item.code}`} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField margin="dense" id="code" label="Start Date" type="text" fullWidth variant="outlined" /> */}
                        <CustomDatePicker label="Start Date" value={props.startDate} onChange={props.setStartDate} />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField margin="dense" id="name" label="End Date" type="text" fullWidth variant="outlined" /> */}
                        <CustomDatePicker label="End Date" value={props.endDate} onChange={props.setEndDate} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Payment Periods</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.paymentPeriod}
                                label="Payment Periods"
                                onChange={(e) => props.setPaymentPeriod(e.target.value)}
                            >
                                {props.paymentPeriods.map((item) => {
                                    return (
                                        <MenuItem key={`payment-${item}`} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            value={props.presentValue}
                            onChange={props.handleChangePresentValue}
                            name="numberformat"
                            margin="dense"
                            id="name"
                            label="Present Value"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            value={props.interest}
                            onChange={(e) => props.setInterest(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Interest"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            value={props.futureValue}
                            onChange={props.handleChangeFutureValue}
                            name="numberformat"
                            margin="dense"
                            id="name"
                            label="Future Value"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
