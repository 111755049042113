// assets
import {
    IconKey,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconUsers,
    IconBuildingBank,
    IconWallet,
    IconCashBanknote,
    IconTransform
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconKey,
    IconUsers,
    IconBuildingBank,
    IconWallet,
    IconCashBanknote,
    IconTransform
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Pages',
    caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            url: '/clients',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: '/payments',
            icon: icons.IconWallet,
            breadcrumbs: false
        }
        // {
        //     id: 'statements',
        //     title: 'Statements',
        //     type: 'item',
        //     url: '/statements',
        //     icon: icons.IconWallet,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'unit-trusts',
        //     title: 'Unit Trusts',
        //     type: 'collapse',
        //     icon: icons.IconBuildingBank,

        //     children: [
        //         {
        //             id: 'view-unit-trusts',
        //             title: 'View All',
        //             type: 'item',
        //             url: '/unit-trusts',
        //             target: false
        //         },
        //         {
        //             id: 'view-unit-trusts-transactions',
        //             title: 'Transactions',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: false
        //         }
        //     ]
        // },
        // {
        //     id: 'authentication',
        //     title: 'Bonds',
        //     type: 'collapse',
        //     icon: icons.IconCashBanknote,

        //     children: [
        //         {
        //             id: 'view-bonds',
        //             title: 'View All',
        //             type: 'item',
        //             url: '/bonds',
        //             target: false
        //         },
        //         {
        //             id: 'view-bonds-transactions',
        //             title: 'Transactions',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: false
        //         }
        //     ]
        // },
        // {
        //     id: 'authentication',
        //     title: 'Cash Accounts',
        //     type: 'collapse',
        //     icon: icons.IconWallet,

        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'View All',
        //             type: 'item',
        //             url: '/pages/login',
        //             target: true
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Transactions',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: true
        //         }
        //     ]
        // }
        // {
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: icons.IconKey,

        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'Login',
        //             type: 'item',
        //             url: '/pages/login',
        //             target: true
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Register',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: true
        //         }
        //     ]
        // }
    ]
};

export default pages;
