// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconCurrencyRipple,
    IconArticle,
    IconKey,
    IconUsers,
    IconBuildingBank,
    IconWallet,
    IconCashBanknote,
    IconTransform
} from '@tabler/icons';

// constant
const icons = {
    IconCurrencyRipple,
    IconArticle,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconKey,
    IconUsers,
    IconBuildingBank,
    IconWallet,
    IconCashBanknote,
    IconTransform
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'unit-trusts',
            title: 'Unit Trusts',
            type: 'item',
            url: '/unit-trusts',
            icon: icons.IconBuildingBank,
            breadcrumbs: false
        },
        {
            id: 'bonds',
            title: 'Bonds',
            type: 'item',
            url: '/bonds',
            icon: icons.IconCashBanknote,
            breadcrumbs: false
        },
        {
            id: 'cash',
            title: 'Cash Accounts',
            type: 'item',
            url: '/cash',
            icon: icons.IconWallet,
            breadcrumbs: false
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: '/transactions',
            icon: icons.IconTransform,
            breadcrumbs: false
        },
        {
            id: 'fund-types',
            title: 'Fund Types',
            type: 'item',
            url: '/fund-types',
            icon: icons.IconCurrencyRipple,
            breadcrumbs: false
        },
        {
            id: 'transaction-types',
            title: 'Transaction Types',
            type: 'item',
            url: '/transaction-types',
            icon: icons.IconArticle,
            breadcrumbs: false
        }
        // {
        //     id: 'util-typography',
        //     title: 'Typography',
        //     type: 'item',
        //     url: '/utils/util-typography',
        //     icon: icons.IconTypography,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-color',
        //     title: 'Color',
        //     type: 'item',
        //     url: '/utils/util-color',
        //     icon: icons.IconPalette,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'icons',
        //     title: 'Icons',
        //     type: 'collapse',
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Tabler Icons',
        //             type: 'item',
        //             url: '/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Material Icons',
        //             type: 'item',
        //             url: '/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
