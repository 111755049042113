import React, { useEffect, useState } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Table from 'ui-component/table';
import { Navigate } from 'react-router-dom';

export default function TransactionTypes() {
    const columns = [
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Name',
            field: 'name'
        }
    ];
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <Table title="Transaction Types" columns={columns} api="/api/transactiontypes" actionsColumnIndex={0} />
            </MainCard>
        </>
    );
}
