/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createRef, useRef, forwardRef } from 'react';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable from 'ui-component/table';
import MaterialTableDefault from 'material-table';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext, TabList } from '@mui/lab';
import apiClient from 'apiClient';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { PDFViewer } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import StatementGenerator from 'ui-component/statement';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Navigate } from 'react-router-dom';

// assets
import { IconFileInvoice } from '@tabler/icons';
import { Grid, InputAdornment, Slide, Typography } from '@mui/material';
import CustomDatePicker from 'ui-component/datepicker';
import { NumericFormat } from 'react-number-format';

// constant
const icons = { IconFileInvoice };
import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import BondDialog from 'ui-component/dialog/bond';
import TransactionDialog from 'ui-component/dialog/transaction';
import CashAccountDialog from 'ui-component/dialog/cashAccount';

import PropTypes from 'prop-types';
import ClientDialog from 'ui-component/dialog/client';
import { render } from 'react-dom';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DeleteDialog from 'ui-component/dialog/delete';
import UnitTrustDialog from 'ui-component/dialog/unitTrust';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={true}
            valueIsNumericString
            prefix="E"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
        color: '#373286'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));

const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
        ref={ref}
        onClick={onClick}
        value={value}
        color="primary"
        focused
        margin="dense"
        id="date-range"
        label="Date Range"
        type="text"
        fullWidth
        variant="outlined"
    />
));

export default function Clients() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [openClient, setOpenClient] = useState(false);
    const [openFund, setOpenFund] = useState(false);
    const [openStatementDialog, setOpenStatementDialog] = useState(false);
    const [dataClient, setDataClient] = useState(null);
    const [dataFund, setDataFund] = useState(null);
    const [statementRowData, setStatementRowData] = useState(null);
    const [statementData, setStatementData] = useState([]);
    const [statementTransactionData, setStatementTransactionData] = useState([]);
    let tempStatementTransactions = [];
    let statementTransactions = [];
    const [totalStartValue, setTotalStartValue] = useState(0.0);
    const [totalEndValue, setTotalEndValue] = useState(0.0);
    const [marketMovement, setMarketMovement] = useState(0.0);
    const [value, setValue] = useState(3);
    const [transactiontypes, setTransactiontypes] = useState([]);
    const [fundtypes, setFundtypes] = useState([]);

    const [totalInvestment, setTotalInvestment] = useState(0);
    const [totalFeePercentage, setTotalFeePercentage] = useState(0);
    const [totalFeeAmount, setTotalFeeAmount] = useState(0.0);

    const [clients, setClients] = useState([]);
    const [clientsRes, setClientsRes] = useState([]);
    var clientsList = [];

    let overviewDataUT = [];
    let overviewDataB = [];

    const [totalUTFee, setTotalUTFee] = useState(0.0);
    const [totalBFee, setTotalBFee] = useState(0.0);
    const [totalUTInvestment, setTotalUTInvestment] = useState(0.0);
    const [totalBInvestment, setTotalBInvestment] = useState(0.0);

    const [bondFeesReady, setBondFeesReady] = useState(false);

    useEffect(() => {
        // console.log(totalFeeAmount);
        // console.log(totalUTFee);
        // console.log(totalFeeAmount - totalUTFee);
        setTotalBFee(totalFeeAmount - totalUTFee);
    }, [totalFeeAmount, totalUTFee]);

    //-------------------- Client State ------------------------------
    const clientTableRef = createRef();
    const clientTableRefUT = createRef();
    const clientTableRefB = createRef();
    const [clientCode, setClientCode] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientFee, setClientFee] = useState(0.0);
    const [clientOpen, setClientOpen] = useState(false);
    const [clientDeleteOpen, setClientDeleteOpen] = useState(false);
    const [clientOperation, setClientOperation] = useState('Add');
    const [clientUuid, setClientUuid] = useState('');

    const handleClickSaveClient = () => {
        setClientOperation('Add');
        setClientOpen(true);
    };

    const handleClickEditClient = (rowData) => {
        setClientOperation('Edit');
        setClientUuid(rowData.uuid);
        setClientCode(rowData.code);
        setClientName(rowData.name);
        setClientFee(rowData.fee);
        setClientOpen(true);
    };

    const handleCloseDeleteClient = () => {
        setClientDeleteOpen(false);
    };

    const handleClickDeleteClient = (rowData) => {
        setClientDeleteOpen(true);

        setClientOperation('Delete');
        setClientUuid(rowData.uuid);
        setClientName(rowData.name);
    };

    const handleCloseClientDialog = (e, reason) => {
        if (reason !== 'backdropClick') {
            setClientOpen(false);
            setClientUuid('');
            setClientCode('');
            setClientName('');
            setClientFee('');
        }
    };

    const handleSaveClient = () => {
        let newData = {
            code: clientCode,
            name: clientName,
            fee: clientFee
        };
        if (clientOperation === 'Add') {
            apiClient
                .post('/api/clients', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseClientDialog();
                    // console.log(data);
                    apiClient
                        .post(
                            '/api/payments',
                            {
                                client_id: data.data.client.id,
                                total_investment: 0,
                                sanlam_fee: 0
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('Token')}`
                                },
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            }
                        )
                        .then((data) => {
                            handleCloseClientDialog();

                            clientTableRef.current;
                            clientTableRef.current.onQueryChange();
                            clientTableRefUT.current;
                            clientTableRefUT.current.onQueryChange();
                            clientTableRefB.current;
                            clientTableRefB.current.onQueryChange();
                        })
                        .catch((e) => {});
                    clientTableRef.current;
                    clientTableRef.current.onQueryChange();
                    clientTableRefUT.current;
                    clientTableRefUT.current.onQueryChange();
                    clientTableRefB.current;
                    clientTableRefB.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (clientOperation === 'Edit') {
            apiClient
                .put('/api/clients/' + clientUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseClientDialog();
                    clientTableRef.current;
                    clientTableRef.current.onQueryChange();
                    clientTableRefUT.current;
                    clientTableRefUT.current.onQueryChange();
                    clientTableRefB.current;
                    clientTableRefB.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (clientOperation === 'Delete') {
            // console.log(clientUuid);
            apiClient
                .delete('/api/clients/' + clientUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteClient();
                    clientTableRef.current;
                    clientTableRef.current.onQueryChange();
                    clientTableRefUT.current;
                    clientTableRefUT.current.onQueryChange();
                    clientTableRefB.current;
                    clientTableRefB.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };
    //-------------------- End Client State --------------------------

    //-------------------- Unit Trust State ------------------------------
    const unitTrustTableRef = createRef();
    const [unitTrustCode, setUnitTrustCode] = useState('');
    const [unitTrustName, setUnitTrustName] = useState('');
    const [unitTrustFee, setUnitTrustFee] = useState(0);
    const [unitTrustOpen, setUnitTrustOpen] = useState(false);
    const [unitTrustOperation, setUnitTrustOperation] = useState('Add');
    const [unitTrustClient, setUnitTrustClient] = useState(null);
    const [unitTrustDeleteOpen, setUnitTrustDeleteOpen] = useState(false);
    const [unitTrustClientsRes, setUnitTrustClientsRes] = useState([]);
    const [unitTrustUuid, setUnitTrustUuid] = useState('');

    const handleCloseDeleteUnitTrust = () => {
        setUnitTrustDeleteOpen(false);
    };

    const handleClickDeleteUnitTrust = (rowData) => {
        setUnitTrustDeleteOpen(true);

        setUnitTrustOperation('Delete');
        setUnitTrustUuid(rowData.uuid);
        setUnitTrustName(rowData.name);
    };

    const handleClickSaveUnitTrust = () => {
        setUnitTrustOperation('Add');
        setUnitTrustOpen(true);
    };

    const handleClickEditUnitTrust = (rowData) => {
        setUnitTrustOperation('Edit');
        setUnitTrustUuid(rowData.uuid);
        setUnitTrustClient(rowData.client_id);
        setUnitTrustCode(rowData.code);
        setUnitTrustName(rowData.name);
        setUnitTrustFee(rowData.fee);
        setUnitTrustOpen(true);
    };

    const handleCloseUnitTrust = (e, reason) => {
        if (reason !== 'backdropClick') {
            setUnitTrustOpen(false);
            setUnitTrustUuid('');
            // setUnitTrustClient(0);
            setUnitTrustCode('');
            setUnitTrustName('');
            setUnitTrustFee(0);
        }
    };

    const handleSaveUnitTrust = () => {
        let newData = {
            code: unitTrustCode,
            name: unitTrustName,
            fee: unitTrustFee,
            client_id: unitTrustClient
        };
        if (unitTrustOperation === 'Add') {
            apiClient
                .post('/api/unittrusts', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseUnitTrust();
                    unitTrustTableRef.current;
                    unitTrustTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (unitTrustOperation === 'Edit') {
            apiClient
                .put('/api/unittrusts/' + unitTrustUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseUnitTrust();
                    unitTrustTableRef.current;
                    unitTrustTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (unitTrustOperation === 'Delete') {
            apiClient
                .delete('/api/unittrusts/' + unitTrustUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteUnitTrust();
                    unitTrustTableRef.current;
                    unitTrustTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };
    //-------------------- End Unit Trust State --------------------------

    //-------------------- Bond State --------------------------------------
    const bondTableRef = createRef();
    const [bondClient, setBondClient] = useState(null);
    const [bondCode, setBondCode] = useState('');
    const [bondName, setBondName] = useState('');
    const [bondPaymentPeriod, setBondPaymentPeriod] = useState(null);
    const [bondPresentValue, setBondPresentValue] = useState('0.0');
    const [bondFutureValue, setBondFutureValue] = useState('0.0');
    const [bondInterest, setBondInterest] = useState(0);
    const [bondStartDate, setBondStartDate] = useState(null);
    const [bondEndDate, setBondEndDate] = useState(null);
    const [bondPaymentPeriods, setBondPaymentPeriods] = useState(['Quarterly', 'Bi-Annually', 'Annually', 'Maturity']);
    const [bondOperation, setBondOperation] = useState('Add');
    const [bondDeleteOpen, setBondDeleteOpen] = useState(false);
    const [bondUuid, setBondUuid] = useState('');
    const [bondOpen, setBondOpen] = useState(false);

    const handleCloseDeleteBond = () => {
        setBondDeleteOpen(false);
    };

    const handleClickDeleteBond = (rowData) => {
        setBondDeleteOpen(true);

        setBondOperation('Delete');
        setBondUuid(rowData.uuid);
        setBondName(rowData.name);
    };

    const handleChangePresentValue = (event) => {
        setBondPresentValue(event.target.value);
    };

    const handleChangeFutureValue = (event) => {
        setBondFutureValue(event.target.value);
    };

    const handleClickSaveBond = () => {
        setBondOperation('Add');
        setBondOpen(true);
    };

    const handleClickEditBond = (rowData) => {
        setBondUuid(rowData.uuid);
        setBondOperation('Edit');
        setBondClient(rowData.client_id);
        setBondCode(rowData.code);
        setBondName(rowData.name);
        setBondPaymentPeriod(rowData.payment_periods);
        setBondPresentValue(rowData.present_value);
        setBondFutureValue(rowData.future_value);
        setBondInterest(rowData.interest);
        setBondStartDate(rowData.start_date);
        setBondEndDate(rowData.end_date);
        setBondOpen(true);
    };

    const handleCloseBond = (e, reason) => {
        if (reason !== 'backdropClick') {
            setBondOpen(false);
            setBondUuid('');
            // setBondClient(0);
            setBondCode('');
            setBondName('');
            setBondPaymentPeriod(null);
            setBondPresentValue('0.0');
            setBondFutureValue('0.0');
            setBondInterest(0);
            setBondStartDate(null);
            setBondEndDate(null);
        }
    };

    const handleSaveBond = () => {
        let newData = {
            code: bondCode,
            name: bondName,
            client_id: bondClient,
            start_date: bondStartDate,
            end_date: bondEndDate,
            payment_periods: bondPaymentPeriod,
            present_value: bondPresentValue,
            interest: bondInterest,
            future_value: bondFutureValue
        };
        if (bondOperation === 'Add') {
            apiClient
                .post('/api/bonds', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseBond();
                    bondTableRef.current;
                    bondTableRef.current.onQueryChange();

                    var _startDate = moment(bondStartDate);
                    var _endDate = moment(bondEndDate);

                    console.log('startDateOUT', _startDate);

                    if (_startDate < _endDate) {
                        var date = _startDate.startOf('month');

                        let _interestValue = bondInterest / 100;
                        let _dailyInterestValue = _interestValue / 365;
                        var a = new Date(bondStartDate).getDate();
                        var b = _startDate.endOf('month').date();
                        let _days = b - a + 1;
                        let _grossAmount = parseFloat(bondPresentValue) + parseFloat(bondPresentValue) * _dailyInterestValue * _days;

                        let newTransactionData = {
                            code: `T-${Date.now()}`,
                            description: '',
                            date: _startDate.endOf('month').format('YYYY-MM-DD'),
                            fund_id: data.data.bond.uuid,
                            opening_balance: 0,
                            transaction_type_id: 3,
                            amount: bondPresentValue * _dailyInterestValue * _days,
                            closing_balance: _grossAmount,
                            fund_type_id: transactionFund_type_id
                        };

                        apiClient
                            .post('/api/transactions', newTransactionData, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('Token')}`
                                },
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            })
                            .then((data) => {})
                            .catch((e) => {
                                console.log('Step 1 Error', e);
                            });

                        date.add(1, 'month');

                        let tempBondValue = _grossAmount;

                        while (date <= _endDate.endOf('month')) {
                            // console.log('-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=>', date.format('MM'));

                            let _days = date.endOf('month').date();
                            // let _grossAmount = tempBondValue + parseFloat(bondPresentValue) * _dailyInterestValue * _days;

                            let _grossAmount = 0.0;

                            if (bondPaymentPeriod === 'Quarterly') {
                                if (
                                    date.format('MM') == '01' ||
                                    date.format('MM') == '04' ||
                                    date.format('MM') == '07' ||
                                    date.format('MM') == '10'
                                ) {
                                    // console.log('-=-=-=-=-=-=-=-=Quarterly-=-=-=-=-=-=-=>', date.format('MM'));
                                    _grossAmount =
                                        parseFloat(bondPresentValue) + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                                } else {
                                    _grossAmount = tempBondValue + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                                }
                            } else if (bondPaymentPeriod === 'Annually') {
                                if (date.format('MM') == '01') {
                                    // console.log('-=-=-=-=-=-=-=-=Annually-=-=-=-=-=-=-=>', date.format('MM'));
                                    _grossAmount =
                                        parseFloat(bondPresentValue) + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                                } else {
                                    _grossAmount = tempBondValue + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                                }
                            } else if (bondPaymentPeriod === 'Bi-Annually') {
                                if (date.format('MM') == '01' || date.format('MM') == '07') {
                                    // console.log('-=-=-=-=-=-=-=-=Bi-Annually-=-=-=-=-=-=-=>', date.format('MM'));
                                    _grossAmount =
                                        parseFloat(bondPresentValue) + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                                } else {
                                    _grossAmount = tempBondValue + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                                }
                            } else {
                                _grossAmount = tempBondValue + parseFloat(bondPresentValue) * _dailyInterestValue * _days;
                            }

                            let newTransactionData = {
                                code: `T-${Date.now()}`,
                                description: '',
                                date: date.format('YYYY-MM-DD'),
                                fund_id: data.data.bond.uuid,
                                opening_balance: tempBondValue,
                                transaction_type_id: 3,
                                amount: bondPresentValue * _dailyInterestValue * _days,
                                closing_balance: _grossAmount,
                                fund_type_id: transactionFund_type_id
                            };

                            tempBondValue = _grossAmount;

                            // if (bondPaymentPeriod === 'Quarterly') {
                            //     if (
                            //         date.format('MM') == '12' ||
                            //         date.format('MM') == '03' ||
                            //         date.format('MM') == '06' ||
                            //         date.format('MM') == '09'
                            //     ) {
                            //         // console.log('-=-=-=-=-=-=-=-=Quarterly-=-=-=-=-=-=-=>', date.format('MM'));
                            //         tempBondValue = parseFloat(bondPresentValue);
                            //     } else {
                            //         tempBondValue = _grossAmount;
                            //     }
                            // } else if (bondPaymentPeriod === 'Annually') {
                            //     if (date.format('MM') == '12') {
                            //         // console.log('-=-=-=-=-=-=-=-=Annually-=-=-=-=-=-=-=>', date.format('MM'));
                            //         tempBondValue = parseFloat(bondPresentValue);
                            //     } else {
                            //         tempBondValue = _grossAmount;
                            //     }
                            // } else if (bondPaymentPeriod === 'Bi-Annually') {
                            //     if (date.format('MM') == '12' || date.format('MM') == '06') {
                            //         // console.log('-=-=-=-=-=-=-=-=Bi-Annually-=-=-=-=-=-=-=>', date.format('MM'));
                            //         tempBondValue = parseFloat(bondPresentValue);
                            //     } else {
                            //         tempBondValue = _grossAmount;
                            //     }
                            // } else {
                            //     tempBondValue = _grossAmount;
                            // }

                            apiClient
                                .post('/api/transactions', newTransactionData, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                                    },
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json'
                                })
                                .then((data) => {})
                                .catch((e) => {
                                    console.log('Step 2 Error', e);
                                });

                            date.add(1, 'month');
                        }
                    }
                })
                .catch((e) => {
                    console.log('End Error', e);
                });
        } else if (bondOperation === 'Edit') {
            apiClient
                .put('/api/bonds/' + bondUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseBond();
                    bondTableRef.current;
                    bondTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (bondOperation === 'Delete') {
            apiClient
                .delete('/api/bonds/' + bondUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteBond();
                    bondTableRef.current;
                    bondTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };
    //-------------------- End Bond State ----------------------------------

    //-------------------- Cash Account State ------------------------------
    const cashAccountTableRef = createRef();
    const [cashAccountCode, setCashAccountCode] = useState('');
    const [cashAccountName, setCashAccountName] = useState('');
    const [cashAccountInterest, setCashAccountInterest] = useState(0);
    const [cashAccountOpen, setCashAccountOpen] = useState(false);
    const [cashAccountOperation, setCashAccountOperation] = useState('Add');
    const [cashAccountClient, setCashAccountClient] = useState(null);
    const [cashAccountDeleteOpen, setCashAccountDeleteOpen] = useState(false);
    const [cashAccountClientsRes, setCashAccountClientsRes] = useState([]);
    const [cashAccountUuid, setCashAccountUuid] = useState('');

    const handleCloseDeleteCashAccount = () => {
        setCashAccountDeleteOpen(false);
    };

    const handleClickDeleteCashAccount = (rowData) => {
        setCashAccountDeleteOpen(true);

        setCashAccountOperation('Delete');
        setCashAccountUuid(rowData.uuid);
        setCashAccountName(rowData.name);
    };

    const handleClickSaveCashAccount = () => {
        setCashAccountOperation('Add');
        setCashAccountOpen(true);
    };

    const handleClickEditCashAccount = (rowData) => {
        setCashAccountOperation('Edit');
        setCashAccountUuid(rowData.uuid);
        setCashAccountClient(rowData.client_id);
        setCashAccountCode(rowData.code);
        setCashAccountName(rowData.name);
        setCashAccountInterest(rowData.interest);
        setCashAccountOpen(true);
    };

    const handleCloseCashAccount = (e, reason) => {
        if (reason !== 'backdropClick') {
            setCashAccountOpen(false);
            setCashAccountUuid('');
            // setCashAccountClient(0);
            setCashAccountCode('');
            setCashAccountName('');
            setCashAccountInterest(0);
        }
    };

    const handleSaveCashAccount = () => {
        let newData = {
            code: cashAccountCode,
            name: cashAccountName,
            interest: cashAccountInterest,
            client_id: cashAccountClient
        };
        if (cashAccountOperation === 'Add') {
            apiClient
                .post('/api/cashaccounts', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseCashAccount();
                    cashAccountTableRef.current;
                    cashAccountTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (cashAccountOperation === 'Edit') {
            apiClient
                .put('/api/cashaccounts/' + cashAccountUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseCashAccount();
                    cashAccountTableRef.current;
                    cashAccountTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (cashAccountOperation === 'Delete') {
            apiClient
                .delete('/api/cashaccounts/' + cashAccountUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteCashAccount();
                    cashAccountTableRef.current;
                    cashAccountTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };
    //-------------------- End Cash Account State --------------------------

    //-------------------- Transaction State -------------------------------
    const transactionTableRef = useRef();
    const [transactionCode, setTransactionCode] = useState('T-0000000000');
    const [transactionDate, setTransactionDate] = useState(null);
    const [transactionDescription, setTransactionDescription] = useState('');
    const [transactionFund_id, setTransactionFundID] = useState(0);
    const [transactionOpening_balance, setTransactionOpeningBalance] = useState(0);
    const [transactionTransaction_type_id, setTransactionTransactionTypeId] = useState(0);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [transactionClosing_balance, setTransactionClosingBalance] = useState(0);
    const [transactionFund_type_id, setTransactionFundTypeId] = useState(3);
    const [funds, setFunds] = useState([]);
    const [transactiontypesres, setTransactiontypesRes] = useState([]);
    const [fundtypesres, setFundtypesRes] = useState([]);
    const [fundsres, setFundsRes] = useState([]);
    var fundsList = [];
    var transactiontypesList = [];
    var fundtypesList = [];
    const [transactionOperation, setTransactionOperation] = useState('Add');
    const [transactionDeleteOpen, setTransactionDeleteOpen] = useState(false);
    const [transactionUuid, setTransactionUuid] = useState('');
    const [transactionOpen, setTransactionOpen] = useState(false);

    const handleCloseDeleteTransaction = () => {
        setTransactionDeleteOpen(false);
    };

    const handleClickDeleteTransaction = (rowData) => {
        setTransactionDeleteOpen(true);

        setTransactionOperation('Delete');
        setTransactionUuid(rowData.uuid);
    };

    const handleChangeOpeningBalanceTransaction = (event) => {
        setTransactionOpeningBalance(event.target.value);
    };

    const handleChangeClosingBalanceTransaction = (event) => {
        setTransactionClosingBalance(event.target.value);
    };

    const handleChangeAmountTransaction = (event) => {
        setTransactionAmount(event.target.value);
    };

    const handleClickSaveTransaction = () => {
        setTransactionOperation('Add');
        // setTransactionOpeningBalance(0);
        setTransactionOpen(true);
    };

    const handleClickEditTransaction = (rowData) => {
        setTransactionUuid(rowData.uuid);
        setTransactionOperation('Edit');
        setTransactionDate(rowData.date);
        setTransactionCode(rowData.code);
        setTransactionDescription(rowData.description);
        setTransactionFundID(rowData.fund_id);
        setTransactionOpeningBalance(rowData.opening_balance);
        setTransactionTransactionTypeId(rowData.transaction_type_id);
        setTransactionAmount(rowData.amount);
        setTransactionClosingBalance(rowData.closing_balance);
        setTransactionFundTypeId(rowData.fund_type_id);
        setTransactionOpen(true);
    };

    const handleCloseTransaction = (e, reason) => {
        if (reason !== 'backdropClick') {
            setTransactionOpen(false);
            setTransactionUuid('');
            setTransactionDate(null);
            setTransactionCode('T-0000000000');
            setTransactionDescription('');
            setTransactionFundID(0);
            setTransactionOpeningBalance(0);
            setTransactionTransactionTypeId(0);
            setTransactionAmount(0);
            setTransactionClosingBalance(0);
            // setTransactionFundTypeId(3);
        }
    };

    const handleSaveTransaction = () => {
        let newData = {
            code: transactionCode,
            description: transactionDescription,
            date: transactionDate,
            fund_id: transactionFund_id,
            opening_balance: transactionOpening_balance,
            transaction_type_id: transactionTransaction_type_id,
            amount: transactionAmount,
            closing_balance: transactionClosing_balance,
            fund_type_id: transactionFund_type_id
        };
        if (transactionOperation === 'Add') {
            apiClient
                .post('/api/transactions', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseTransaction();
                    transactionTableRef.current;
                    transactionTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (transactionOperation === 'Edit') {
            apiClient
                .put('/api/transactions/' + transactionUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    // console.log(data);
                    handleCloseTransaction();
                    // console.log(transactionTableRef);
                    transactionTableRef.current;
                    transactionTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (transactionOperation === 'Delete') {
            apiClient
                .delete('/api/transactions/' + transactionUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteTransaction();
                    transactionTableRef.current;
                    transactionTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };
    //-------------------- End Transaction State ---------------------------

    var transactiontypesList = [];
    var fundtypesList = [];

    useEffect(() => {
        apiClient
            .get(`/api/transactiontypes`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setTransactiontypesRes(res.data.data);
                res.data.data.map((item) => {
                    transactiontypesList[item.id] = item.name;
                    return null;
                });
                setTransactiontypes(transactiontypesList);
            })
            .catch((e) => {
                console.log(e);
            });

        apiClient
            .get(`/api/funds`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setFundsRes([...res.data.unitTrusts.data, ...res.data.bonds.data, ...res.data.cashAccounts.data]);
                [...res.data.unitTrusts.data, ...res.data.bonds.data, ...res.data.cashAccounts.data].map((item) => {
                    fundsList[item.uuid] = item.name;
                    return null;
                });
                setFunds(fundsList);
            })
            .catch((e) => {
                console.log(e);
            });

        apiClient
            .get(`/api/fundtypes`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                setFundtypesRes(res.data.data);
                res.data.data.map((item) => {
                    fundtypesList[item.id] = item.name;
                    return null;
                });
                setFundtypes(fundtypesList);
            })
            .catch((e) => {
                console.log(e);
            });

        apiClient
            .get(`/api/clients`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                // console.log(res.data.data);
                setClientsRes(res.data.data);
                res.data.data.map((item) => {
                    clientsList[item.id] = item.name;
                    return null;
                });
                setClients(clientsList);
            })
            .catch((e) => {
                console.log(e);
            });

        if (localStorage.getItem('Token')) {
            setLoggedIn(true);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            getStatementData();
            setBondFeesReady(false);
        } else {
            setBondFeesReady(false);
            setTotalBFee(0.0);
            setTotalBInvestment(0.0);
            setTotalEndValue(0.0);
            setTotalUTFee(0.0);
            setTotalUTInvestment(0.0);
            setTotalFeeAmount(0.0);
        }
    }, [startDate, endDate]);

    const overviewUTColumns = [
        {
            title: 'Code',
            field: 'code',
            editable: 'never'
        },
        {
            title: 'Name',
            field: 'name',
            editable: 'never'
        },
        {
            title: 'Fee',
            field: 'fee',
            editable: 'never',
            render: (rowData) => <p>{rowData.fee} %</p>
        },
        {
            title: 'Funds Invested',
            field: 'end_value',
            editable: 'never',
            render: (rowData) => <NumericFormat value={rowData.end_value} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Weighted Average',
            field: 'weighted_average',
            editable: 'never'
        },
        {
            title: 'Gross Investment',
            field: 'gross_investment',
            editable: 'never',
            render: (rowData) => (
                <NumericFormat value={rowData.gross_investment.toFixed(2)} displayType="text" thousandSeparator={true} prefix="E" />
            )
        },
        {
            title: 'Fee',
            field: 'cash',
            editable: 'never',
            render: (rowData) => <NumericFormat value={rowData.cash.toFixed(2)} displayType="text" thousandSeparator={true} prefix="E" />
        }
    ];

    const overviewBColumns = [
        {
            title: 'Code',
            field: 'code',
            editable: 'never'
        },
        {
            title: 'Name',
            field: 'name',
            editable: 'never'
        },
        {
            title: 'Interest',
            field: 'interest',
            editable: 'never',
            render: (rowData) => <p>{rowData.interest} %</p>
        },
        {
            title: 'Funds Invested',
            field: 'start_value',
            editable: 'never',
            render: (rowData) => <NumericFormat value={rowData.start_value} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Gross Investment',
            field: 'gross_investment',
            editable: 'never',
            render: (rowData) => <NumericFormat value={rowData.gross_investment} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Weighted Average',
            field: 'weighted_average',
            editable: 'never'
        },
        {
            title: 'Fee',
            field: 'cash',
            editable: 'never',
            render: (rowData) => <NumericFormat value={rowData.cash.toFixed(2)} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Net Investment',
            field: 'gross_investment',
            editable: 'never',
            render: (rowData) => (
                <NumericFormat
                    value={(rowData.gross_investment - rowData.cash).toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="E"
                />
            )
        }
    ];

    const columns = [
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Name',
            field: 'name'
        },
        {
            title: 'Fee',
            field: 'fee',
            type: 'numeric',
            render: (rowData) => <p>{rowData.fee} %</p>
        }
    ];

    const utColumns = [
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Unit Trust Name',
            field: 'name'
        },
        {
            title: 'Fee',
            field: 'fee',
            type: 'numeric',
            render: (rowData) => <p>{rowData.fee} %</p>
        },
        {
            title: 'Client',
            field: 'client_id',
            initialEditValue: dataClient != null ? dataClient.id : 0,
            editable: 'never',
            render: (rowData) => <p>{dataClient.name}</p>
        }
    ];

    const bColumns = [
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Bond Name',
            field: 'name'
        },
        {
            title: 'Start Date',
            field: 'start_date',
            type: 'datetime',
            editComponent: (props) => <CustomDatePicker value={props.value} onChange={props.onChange} />,
            render: (rowData) => <p>{moment(rowData.start_date).format('DD MMM YYYY')}</p>
        },
        {
            title: 'End Date',
            field: 'end_date',
            type: 'datetime',
            editComponent: (props) => <CustomDatePicker value={props.value} onChange={props.onChange} />,
            render: (rowData) => <p>{moment(rowData.end_date).format('DD MMM YYYY')}</p>
        },
        {
            title: 'Payment Periods',
            field: 'payment_periods',
            lookup: { Quarterly: 'Quarterly', 'Bi-Annually': 'Bi-Annually', Annually: 'Annually', Maturity: 'Maturity' }
        },
        {
            title: 'Present Value',
            field: 'present_value',
            type: 'numeric',
            align: 'left',
            render: (rowData) => <NumericFormat value={rowData.present_value} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Interest',
            field: 'interest',
            type: 'numeric',
            render: (rowData) => <p>{rowData.interest} %</p>
        },
        {
            title: 'Future Value',
            field: 'future_value',
            type: 'numeric',
            align: 'left',
            render: (rowData) => <NumericFormat value={rowData.future_value} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Month Created',
            field: 'created_at',
            type: 'date',
            editable: 'never',
            render: (rowData) => <p>{moment(rowData.created_at).format('MMMM')}</p>
        },
        {
            title: 'Client',
            field: 'client_id',
            initialEditValue: dataClient != null ? dataClient.id : 0,
            editable: 'never',
            render: (rowData) => <p>{dataClient.name}</p>
        }
    ];

    const caColumns = [
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Cash Account Name',
            field: 'name'
        },
        {
            title: 'Interest',
            field: 'interest',
            type: 'numeric',
            render: (rowData) => <p>{rowData.interest} %</p>
        },
        {
            title: 'Client',
            field: 'client_id',
            initialEditValue: dataClient != null ? dataClient.id : 0,
            editable: 'never',
            render: (rowData) => <p>{dataClient.name}</p>
        }
    ];

    const tColumns = [
        {
            title: 'Code',
            field: 'code',
            hidden: true
        },
        {
            title: 'Description',
            field: 'description',
            hidden: true
        },
        {
            title: 'Opening Balance',
            field: 'opening_balance',
            type: 'numeric',
            align: 'left',
            render: (rowData) => <NumericFormat value={rowData.opening_balance} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Transaction Type',
            field: 'transaction_type_id',
            lookup: transactiontypes,
            initialEditValue: null
        },
        {
            title: 'Date',
            field: 'date',
            type: 'date',
            editComponent: (props) => <CustomDatePicker value={props.value} onChange={props.onChange} />,
            render: (rowData) => <p>{moment(rowData.date).format('DD MMM YYYY')}</p>
        },
        {
            title: 'Amount',
            field: 'amount',
            type: 'numeric',
            align: 'left',
            render: (rowData) => <NumericFormat value={rowData.amount} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Closing Balance',
            field: 'closing_balance',
            type: 'numeric',
            align: 'left',
            render: (rowData) => <NumericFormat value={rowData.closing_balance} displayType="text" thousandSeparator={true} prefix="E" />
        },
        {
            title: 'Fund Type',
            field: 'fund_type_id',
            lookup: fundtypes,
            initialEditValue: null
        },
        {
            title: 'Fund',
            field: 'fund_id',
            initialEditValue: dataFund != null ? dataFund.uuid : 0,
            editable: 'never',
            render: (rowData) => <p>{dataFund.name}</p>
        }
    ];

    const handleChange = (event, newValue) => {
        setTransactionFundTypeId(newValue);
        setValue(newValue);
    };

    const handleClickOpenClient = (rowData) => {
        setStatementRowData(rowData);
        setDataClient(rowData);
        setUnitTrustClient(rowData.id);
        setBondClient(rowData.id);
        setCashAccountClient(rowData.id);
        setOpenClient(true);
    };
    const handleCloseClient = (e, reason) => {
        if (reason !== 'backdropClick') {
            setBondFeesReady(false);
            setTotalBFee(0.0);
            setTotalBInvestment(0.0);
            setTotalEndValue(0.0);
            setTotalUTFee(0.0);
            setTotalUTInvestment(0.0);
            setTotalFeeAmount(0.0);
            setDateRange([null, null]);
            setTransactionFundTypeId(3);
            setValue(3);
            setOpenClient(false);
        }
    };

    const handleClickOpenFund = (rowData) => {
        setTransactionFundID(rowData.uuid);
        setDataFund(rowData);
        setOpenFund(true);

        apiClient
            .get(`/api/transactions-by-fund-last/${rowData != null ? rowData.uuid : ''}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
                Accept: 'application/json',
                'Content-Type': 'application/json'
            })
            .then((res) => {
                // console.log(res);
                if (res.data.closing_balance) setTransactionOpeningBalance(res.data.closing_balance);
                // setOpenFund(true);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const handleCloseFund = (e, reason) => {
        if (reason !== 'backdropClick') {
            setOpenFund(false);
        }
    };

    const handleOpenStatementDialog = (rowData) => {
        setStatementRowData(rowData);
        setOpenStatementDialog(true);
    };
    const handleCloseStatementDialog = (e, reason) => {
        if (reason !== 'backdropClick') {
            setDateRange([null, null]);
            setOpenStatementDialog(false);
        }
    };

    const getStatementData = () => {
        apiClient
            .post(
                `/api/statement/${statementRowData.id}`,
                { startDate: startDate, endDate: endDate },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            )
            .then((data) => {
                var tempStartValue = data.data.totalStartValue;

                data.data.statement.forEach((element) => {
                    element.transactions.forEach((item) => {
                        if (item.description === 'Additional Investment') {
                            tempStatementTransactions.push(item);
                            tempStartValue = tempStartValue + item.amount;
                        } else if (item.description === 'Disinvestment') {
                            tempStatementTransactions.push(item);
                            tempStartValue = tempStartValue - item.amount;
                        }
                    });
                });
                statementTransactions.push({
                    date: moment(startDate).format('DD MMM YYYY'),
                    period: 'Opening Balance',
                    total: data.data.totalStartValue
                });
                tempStatementTransactions.forEach((item) => {
                    statementTransactions.push({
                        date: moment(item.date).format('DD MMM YYYY'),
                        period: item.description,
                        total: item.amount
                    });
                });
                statementTransactions.push({
                    date: moment(endDate).subtract(1, 'days').format('DD MMM YYYY'),
                    period: 'Market Movement',
                    total: data.data.marketMovement
                });
                statementTransactions.push({
                    date: moment(endDate).subtract(1, 'days').format('DD MMM YYYY'),
                    period: 'Closing Balance',
                    total: totalEndValue - tempStartValue
                });
                setStatementTransactionData(statementTransactions);
                setStatementData(data.data.statement);
                setTotalStartValue(data.data.totalStartValue);
                setTotalEndValue(data.data.totalEndValue);
                setTotalFeeAmount(data.data.totalEndValue * (dataClient != null ? dataClient.fee / 100 / 12 : 0));
                setMarketMovement(data.data.marketMovement);
            })
            .catch((e) => {});
    };

    return (
        <>
            {!loggedIn && !loading ? <Navigate to="/pages/login" /> : null}
            <MainCard>
                <MaterialTable
                    title="Clients"
                    columns={columns}
                    api="/api/clients"
                    actionsColumnIndex={0}
                    customActions={true}
                    tableRef={clientTableRef}
                    actions={[
                        // {
                        //     icon: IconFileInvoice,
                        //     tooltip: 'Generate Statement',
                        //     onClick: (event, rowData) => handleOpenStatementDialog(rowData)
                        // },
                        {
                            icon: AddBox,
                            tooltip: 'Add Client',
                            isFreeAction: true,
                            onClick: (event) => handleClickSaveClient()
                        },
                        {
                            icon: Edit,
                            tooltip: 'Edit Client',
                            onClick: (event, rowData) => {
                                handleClickEditClient(rowData);
                            }
                        },
                        {
                            icon: DeleteOutline,
                            tooltip: 'Delete Client',
                            onClick: (event, rowData) => {
                                handleClickDeleteClient(rowData);
                            }
                        }
                    ]}
                    onRowClick={(event, rowData, togglePanel) => handleClickOpenClient(rowData)}
                />
                {/* ------------------------ Client Dialog -------------------------------------- */}
                <Dialog fullWidth={true} maxWidth={'xl'} open={openClient} onClose={handleCloseClient}>
                    <DialogTitle>{dataClient != null ? dataClient.name : 'Client'}</DialogTitle>
                    <DialogContent>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs" centered style={{ backgroundColor: '#9692b3' }}>
                                    <StyledTab label="Unit Trusts" value={3} />
                                    <StyledTab label="Bonds" value={2} />
                                    <StyledTab label="Cash Account" value={1} />
                                    <StyledTab label="Fees" value={4} />
                                </TabList>
                            </Box>
                            <TabPanel value={3}>
                                <MaterialTable
                                    title="Unit Trusts"
                                    columns={utColumns}
                                    api={`/api/unittrusts-by-client/${dataClient != null ? dataClient.id : ''}`}
                                    actionsColumnIndex={0}
                                    onRowClick={(event, rowData, togglePanel) => handleClickOpenFund(rowData)}
                                    tableRef={unitTrustTableRef}
                                    customActions={true}
                                    actions={[
                                        {
                                            icon: AddBox,
                                            tooltip: 'Add Unit Trust',
                                            isFreeAction: true,
                                            onClick: (event) => handleClickSaveUnitTrust()
                                        },
                                        {
                                            icon: Edit,
                                            tooltip: 'Edit Unit Trust',
                                            onClick: (event, rowData) => {
                                                handleClickEditUnitTrust(rowData);
                                            }
                                        },
                                        {
                                            icon: DeleteOutline,
                                            tooltip: 'Delete Unit Trust',
                                            onClick: (event, rowData) => {
                                                handleClickDeleteUnitTrust(rowData);
                                            }
                                        }
                                    ]}
                                />
                            </TabPanel>
                            <TabPanel value={2}>
                                <MaterialTable
                                    title="Bonds"
                                    columns={bColumns}
                                    api={`/api/bonds-by-client/${dataClient != null ? dataClient.id : ''}`}
                                    actionsColumnIndex={0}
                                    onRowClick={(event, rowData, togglePanel) => handleClickOpenFund(rowData)}
                                    filtering={true}
                                    tableRef={bondTableRef}
                                    customActions={true}
                                    actions={[
                                        {
                                            icon: AddBox,
                                            tooltip: 'Add Bond',
                                            isFreeAction: true,
                                            onClick: (event) => handleClickSaveBond()
                                        },
                                        {
                                            icon: Edit,
                                            tooltip: 'Edit Bond',
                                            onClick: (event, rowData) => {
                                                handleClickEditBond(rowData);
                                            }
                                        },
                                        {
                                            icon: DeleteOutline,
                                            tooltip: 'Delete Bond',
                                            onClick: (event, rowData) => {
                                                handleClickDeleteBond(rowData);
                                            }
                                        }
                                    ]}
                                />
                            </TabPanel>
                            <TabPanel value={1}>
                                <MaterialTable
                                    title="Cash Accounts"
                                    columns={caColumns}
                                    api={`/api/cashaccounts-by-client/${dataClient != null ? dataClient.id : ''}`}
                                    actionsColumnIndex={0}
                                    onRowClick={(event, rowData, togglePanel) => handleClickOpenFund(rowData)}
                                    tableRef={cashAccountTableRef}
                                    customActions={true}
                                    actions={[
                                        {
                                            icon: AddBox,
                                            tooltip: 'Add Cash Account',
                                            isFreeAction: true,
                                            onClick: (event) => handleClickSaveCashAccount()
                                        },
                                        {
                                            icon: Edit,
                                            tooltip: 'Edit Cash Account',
                                            onClick: (event, rowData) => {
                                                handleClickEditCashAccount(rowData);
                                            }
                                        },
                                        {
                                            icon: DeleteOutline,
                                            tooltip: 'Delete Cash Account',
                                            onClick: (event, rowData) => {
                                                handleClickDeleteCashAccount(rowData);
                                            }
                                        }
                                    ]}
                                />
                            </TabPanel>
                            <TabPanel value={4}>
                                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                                    <Grid item xs={3}>
                                        <DatePicker
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                if (update[1]) {
                                                    var newDate = new Date(update[1].setTime(update[1].getTime() + 1 * 86400000));
                                                }
                                                setDateRange(update);
                                            }}
                                            onClickOutside={() => null}
                                            withPortal
                                            placeholderText="Date Range"
                                            isClearable={true}
                                            fixedHeight={true}
                                            customInput={<DateCustomInput />}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                            value={totalEndValue.toFixed(2)}
                                            name="total-investment"
                                            margin="dense"
                                            id="total-investment"
                                            label="Total Investment"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                                            }}
                                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                            value={dataClient != null ? dataClient.fee : 0}
                                            disabled
                                            // value={totalFeePercentage}
                                            // onChange={(e) => {
                                            //     setTotalFeePercentage(e.target.value);
                                            //     setTotalFeeAmount(totalEndValue * (e.target.value / 100 / 12));
                                            // }}
                                            margin="dense"
                                            id="total-fee-percentage"
                                            label="Total Fee Percentage"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                            value={totalFeeAmount.toFixed(2)}
                                            name="total-fee-amount"
                                            margin="dense"
                                            id="total-fee-amount"
                                            label="Total Fee Amount"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                            value={totalUTInvestment.toFixed(2)}
                                            name="total-ut-investment"
                                            margin="dense"
                                            id="total-ut-investment"
                                            label="Total Unit Trust Investment"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                            value={totalUTFee.toFixed(2)}
                                            name="total-ut-fee"
                                            margin="dense"
                                            id="total-ut-fee"
                                            label="Total Unit Trust Fee"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {startDate !== null && endDate !== null ? (
                                            <>
                                                <MaterialTableDefault
                                                    title="Unit Trust Overview"
                                                    columns={overviewUTColumns}
                                                    icons={tableIcons}
                                                    data={(query) =>
                                                        new Promise((resolve, reject) => {
                                                            apiClient
                                                                .post(
                                                                    `/api/statement/${statementRowData.id}`,
                                                                    {
                                                                        startDate: startDate,
                                                                        endDate: endDate
                                                                    },
                                                                    {
                                                                        headers: {
                                                                            Authorization: `Bearer ${localStorage.getItem('Token')}`
                                                                        },
                                                                        Accept: 'application/json',
                                                                        'Content-Type': 'application/json'
                                                                    }
                                                                )
                                                                .then((data) => {
                                                                    let tempTotalUTInvestment = 0.0;
                                                                    let tempTotalUTFee = 0.0;
                                                                    for (var i = 0; i < data.data.unit_trusts.length; i++) {
                                                                        let feeValue = data.data.unit_trusts[i].fee / 100;
                                                                        let monthlyFeeValue = feeValue / 12;
                                                                        tempTotalUTInvestment =
                                                                            tempTotalUTInvestment +
                                                                            parseFloat(data.data.unit_trusts[i].end_value);
                                                                        tempTotalUTFee =
                                                                            tempTotalUTFee +
                                                                            data.data.unit_trusts[i].end_value / (1 - monthlyFeeValue) -
                                                                            data.data.unit_trusts[i].end_value;
                                                                        overviewDataUT.push({
                                                                            code: data.data.unit_trusts[i].code,
                                                                            name: data.data.unit_trusts[i].name,
                                                                            fee: data.data.unit_trusts[i].fee,
                                                                            start_value: data.data.unit_trusts[i].start_value,
                                                                            end_value: data.data.unit_trusts[i].end_value,
                                                                            weighted_average: (
                                                                                (data.data.unit_trusts[i].end_value /
                                                                                    data.data.totalEndValue) *
                                                                                100
                                                                            ).toFixed(2),
                                                                            gross_investment:
                                                                                data.data.unit_trusts[i].end_value / (1 - monthlyFeeValue),
                                                                            cash:
                                                                                data.data.unit_trusts[i].end_value / (1 - monthlyFeeValue) -
                                                                                data.data.unit_trusts[i].end_value
                                                                        });
                                                                    }

                                                                    setTotalUTFee(tempTotalUTFee);
                                                                    setTotalUTInvestment(tempTotalUTInvestment);
                                                                    setStatementData(overviewDataUT);
                                                                    resolve({
                                                                        data: overviewDataUT
                                                                    });
                                                                })
                                                                .catch((e) => {});
                                                        })
                                                    }
                                                    tableRef={clientTableRefUT}
                                                    options={{
                                                        actionsColumnIndex: 0,
                                                        exportButton: false,
                                                        filtering: false,
                                                        addRowPosition: 'first',
                                                        loadingType: 'overlay',
                                                        search: false,
                                                        paging: false
                                                    }}
                                                    // cellEditable={{
                                                    //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                    //         return new Promise((resolve, reject) => {
                                                    //             rowData.fund_fee = newValue;
                                                    //             let feeValue = newValue / 100;
                                                    //             let monthlyFeeValue = feeValue / 12;
                                                    //             // eslint-disable-next-line prettier/prettier
                                                    //             rowData.gross_investment = ( // eslint-disable-next-line prettier/prettier
                                                    //                 (rowData.end_value / (1 - monthlyFeeValue))
                                                    //             ).toFixed(2);
                                                    //             rowData.cash = (rowData.gross_investment - rowData.end_value).toFixed(2);
                                                    //             let tempResult = 0.0;
                                                    //             clientTableRefUT.current.state.data.map((item) => {
                                                    //                 tempResult = tempResult + parseFloat(item.cash);
                                                    //             });
                                                    //             setTotalUTFee(tempResult);
                                                    //             setTotalBFee(totalFeeAmount - tempResult);
                                                    //             resolve();
                                                    //         });
                                                    //     }
                                                    // }}
                                                />
                                            </>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setBondFeesReady(true);
                                            }}
                                        >
                                            Generate Bond Fees
                                        </Button>
                                    </Grid>
                                    {bondFeesReady ? (
                                        <>
                                            <Grid item xs={3}>
                                                <TextField
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom
                                                    }}
                                                    value={totalBInvestment.toFixed(2)}
                                                    name="total-b-investment"
                                                    margin="dense"
                                                    id="total-b-investment"
                                                    label="Total Bond Investment"
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom
                                                    }}
                                                    value={totalBFee.toFixed(2)}
                                                    name="total-b-fee"
                                                    margin="dense"
                                                    id="total-b-fee"
                                                    label="Total Bond Fee"
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MaterialTableDefault
                                                    title="Bonds Overview"
                                                    columns={overviewBColumns}
                                                    icons={tableIcons}
                                                    data={(query) =>
                                                        new Promise((resolve, reject) => {
                                                            apiClient
                                                                .post(
                                                                    `/api/statement/${statementRowData.id}`,
                                                                    {
                                                                        startDate: startDate,
                                                                        endDate: endDate
                                                                    },
                                                                    {
                                                                        headers: {
                                                                            Authorization: `Bearer ${localStorage.getItem('Token')}`
                                                                        },
                                                                        Accept: 'application/json',
                                                                        'Content-Type': 'application/json'
                                                                    }
                                                                )
                                                                .then((data) => {
                                                                    let tempTotalBInvestment = 0.0;
                                                                    let tempTotalBInterest = 0.0;
                                                                    for (var i = 0; i < data.data.bonds.length; i++) {
                                                                        tempTotalBInvestment =
                                                                            tempTotalBInvestment + parseFloat(data.data.bonds[i].end_value);
                                                                    }
                                                                    // console.log('data.data.bonds: ', data.data.bonds);
                                                                    setTotalBInvestment(parseFloat(tempTotalBInvestment));
                                                                    for (var i = 0; i < data.data.bonds.length; i++) {
                                                                        let weighted_average = (
                                                                            (parseFloat(data.data.bonds[i].end_value) /
                                                                                tempTotalBInvestment) *
                                                                            100
                                                                        ).toFixed(2);
                                                                        // console.log(totalBFee);
                                                                        // console.log(weighted_average);
                                                                        // console.log(totalBFee * (weighted_average / 100));
                                                                        overviewDataB.push({
                                                                            code: data.data.bonds[i].code,
                                                                            name: data.data.bonds[i].name,
                                                                            interest: data.data.bonds[i].interest,
                                                                            start_value: data.data.bonds[i].start_value,
                                                                            end_value:
                                                                                data.data.bonds[i].end_value -
                                                                                totalBFee * (weighted_average / 100),
                                                                            weighted_average: weighted_average,
                                                                            gross_investment: data.data.bonds[i].end_value,
                                                                            cash: totalBFee * (weighted_average / 100)
                                                                        });

                                                                        // tempTotalBInterest =
                                                                        //     tempTotalBInterest + parseFloat(data.data.bonds[i].end_value);
                                                                    }
                                                                    setStatementData([...statementData, ...overviewDataB]);
                                                                    // setTotalEndValue(totalEndValue + tempTotalBInterest);

                                                                    var tempStartValue2 = data.data.totalStartValue;

                                                                    data.data.statement.forEach((element) => {
                                                                        element.transactions.forEach((item) => {
                                                                            if (item.description === 'Additional Investment') {
                                                                                tempStatementTransactions.push(item);
                                                                                tempStartValue2 = tempStartValue2 + item.amount;
                                                                            } else if (item.description === 'Disinvestment') {
                                                                                tempStatementTransactions.push(item);
                                                                                tempStartValue2 = tempStartValue2 - item.amount;
                                                                            }
                                                                        });
                                                                    });
                                                                    statementTransactions.push({
                                                                        date: moment(startDate).format('DD MMM YYYY'),
                                                                        period: 'Opening Balance',
                                                                        total: data.data.totalStartValue
                                                                    });
                                                                    tempStatementTransactions.forEach((item) => {
                                                                        statementTransactions.push({
                                                                            date: moment(item.date).format('DD MMM YYYY'),
                                                                            period: item.description,
                                                                            total: item.amount
                                                                        });
                                                                    });
                                                                    statementTransactions.push({
                                                                        date: moment(endDate).subtract(1, 'days').format('DD MMM YYYY'),
                                                                        period: 'Market Movement',
                                                                        total: totalEndValue + tempTotalBInterest - tempStartValue2
                                                                        // total: data.data.marketMovement
                                                                    });
                                                                    statementTransactions.push({
                                                                        date: moment(endDate).subtract(1, 'days').format('DD MMM YYYY'),
                                                                        period: 'Closing Balance',
                                                                        total: totalEndValue + tempTotalBInterest
                                                                    });
                                                                    setStatementTransactionData(statementTransactions);
                                                                    resolve({
                                                                        data: overviewDataB
                                                                    });
                                                                })
                                                                .catch((e) => {});
                                                        })
                                                    }
                                                    tableRef={clientTableRefB}
                                                    options={{
                                                        actionsColumnIndex: 0,
                                                        exportButton: false,
                                                        filtering: false,
                                                        addRowPosition: 'first',
                                                        loadingType: 'overlay',
                                                        search: false,
                                                        paging: false
                                                    }}
                                                    // cellEditable={{
                                                    //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                    //         return new Promise((resolve, reject) => {
                                                    //             rowData.fund_fee = newValue;
                                                    //             rowData.gross_investment = (
                                                    //                 rowData.end_value /
                                                    //                 (100 - rowData.fund_fee)
                                                    //             ).toFixed(2);
                                                    //             rowData.cash = (rowData.gross_investment - rowData.end_value).toFixed(2);
                                                    //             // console.log(rowData.gross_investment);
                                                    //             // console.log(rowData.end_value);
                                                    //             // console.log('newValue: ' + newValue);
                                                    //             // console.log('oldValue: ' + oldValue);
                                                    //             // console.log('rowData: ', rowData);
                                                    //             // console.log('columnDef: ', columnDef);
                                                    //             // setTimeout(resolve, 1000);
                                                    //             resolve();
                                                    //         });
                                                    //     }
                                                    // }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setOpenStatementDialog(true);
                                                    }}
                                                >
                                                    Generate Statement
                                                </Button>
                                            </Grid>
                                        </>
                                    ) : null}
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseClient}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth={true} maxWidth={'lx'} open={openFund} onClose={handleCloseFund}>
                    <DialogTitle>{dataFund != null ? dataFund.name : 'Fund'}</DialogTitle>
                    <DialogContent>
                        <MaterialTable
                            title="Transactions"
                            columns={tColumns}
                            api={`/api/transactions-by-fund/${dataFund != null ? dataFund.uuid : ''}`}
                            actionsColumnIndex={0}
                            filtering={true}
                            tableRef={transactionTableRef}
                            customActions={true}
                            pageSize={10}
                            actions={[
                                {
                                    icon: AddBox,
                                    tooltip: 'Add Transaction',
                                    isFreeAction: true,
                                    onClick: (event) => handleClickSaveTransaction()
                                },
                                {
                                    icon: Edit,
                                    tooltip: 'Edit Transaction',
                                    onClick: (event, rowData) => {
                                        handleClickEditTransaction(rowData);
                                    }
                                },
                                {
                                    icon: DeleteOutline,
                                    tooltip: 'Delete Transaction',
                                    onClick: (event, rowData) => {
                                        handleClickDeleteTransaction(rowData);
                                    }
                                }
                            ]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseFund}>
                            Back
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* ------------------------ Statement Dialog -------------------------------------- */}
                <Dialog
                    fullScreen
                    open={openStatementDialog}
                    onClose={(e, reason) => {
                        handleCloseStatementDialog(e, reason);
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    handleCloseStatementDialog();
                                }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Statement Generator
                            </Typography>
                            {/* <Button
                                color="inherit"
                                onClick={() => {
                                    handleCloseStatementDialog();
                                }}
                            >
                                Download
                            </Button> */}
                        </Toolbar>
                    </AppBar>
                    <div style={{ margin: '10px' }}>
                        <h2>Generate Statement for {statementRowData ? statementRowData.name : 'Client'}</h2>
                        {/* <div style={{ width: 'fit-content' }}>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setDateRange(update);
                                }}
                                withPortal
                                placeholderText="Date Range"
                                isClearable={true}
                            />
                        </div> */}
                    </div>
                    {startDate !== null && endDate !== null ? (
                        <PDFViewer style={{ width: '100%', height: '100vh' }}>
                            <StatementGenerator
                                startDate={startDate}
                                endDate={endDate}
                                rowData={statementRowData}
                                data={statementData}
                                transactionData={statementTransactionData}
                                totalStartValue={totalStartValue}
                                totalEndValue={totalEndValue}
                                marketMovement={marketMovement}
                            />
                        </PDFViewer>
                    ) : null}
                </Dialog>
                {/* ------------------------ Client Dialog -------------------------------------- */}
                <ClientDialog
                    open={clientOpen}
                    handleClose={handleCloseClientDialog}
                    code={clientCode}
                    setCode={setClientCode}
                    name={clientName}
                    setName={setClientName}
                    fee={clientFee}
                    setFee={setClientFee}
                    handleSave={handleSaveClient}
                />
                <DeleteDialog
                    open={clientDeleteOpen}
                    handleClose={handleCloseDeleteClient}
                    handleSave={handleSaveClient}
                    label={clientName}
                />
                {/* ------------------------ Unit Trust Dialog -------------------------------------- */}
                <UnitTrustDialog
                    open={unitTrustOpen}
                    handleClose={handleCloseUnitTrust}
                    code={unitTrustCode}
                    setCode={setUnitTrustCode}
                    name={unitTrustName}
                    setName={setUnitTrustName}
                    client={unitTrustClient}
                    setClient={setUnitTrustClient}
                    disableClient={true}
                    clientsRes={clientsRes}
                    fee={unitTrustFee}
                    setFee={setUnitTrustFee}
                    handleSave={handleSaveUnitTrust}
                />
                <DeleteDialog
                    open={unitTrustDeleteOpen}
                    handleClose={handleCloseDeleteUnitTrust}
                    handleSave={handleSaveUnitTrust}
                    label={unitTrustName}
                />
                {/* ------------------------ Bond Dialog -------------------------------------- */}
                <BondDialog
                    open={bondOpen}
                    handleClose={handleCloseBond}
                    code={bondCode}
                    setCode={setBondCode}
                    name={bondName}
                    setName={setBondName}
                    client={bondClient}
                    setClient={setBondClient}
                    disableClient={true}
                    clientsRes={clientsRes}
                    startDate={bondStartDate}
                    setStartDate={setBondStartDate}
                    endDate={bondEndDate}
                    setEndDate={setBondEndDate}
                    paymentPeriod={bondPaymentPeriod}
                    paymentPeriods={bondPaymentPeriods}
                    setPaymentPeriod={setBondPaymentPeriod}
                    presentValue={bondPresentValue}
                    handleChangePresentValue={handleChangePresentValue}
                    interest={bondInterest}
                    setInterest={setBondInterest}
                    futureValue={bondFutureValue}
                    handleChangeFutureValue={handleChangeFutureValue}
                    handleSave={handleSaveBond}
                />
                <DeleteDialog open={bondDeleteOpen} handleClose={handleCloseDeleteBond} handleSave={handleSaveBond} label={bondName} />
                {/* ------------------------ Cash Account Dialog -------------------------------------- */}
                <CashAccountDialog
                    open={cashAccountOpen}
                    handleClose={handleCloseCashAccount}
                    code={cashAccountCode}
                    setCode={setCashAccountCode}
                    name={cashAccountName}
                    setName={setCashAccountName}
                    client={cashAccountClient}
                    setClient={setCashAccountClient}
                    disableClient={true}
                    clientsRes={clientsRes}
                    interest={cashAccountInterest}
                    setInterest={setCashAccountInterest}
                    handleSave={handleSaveCashAccount}
                />
                <DeleteDialog
                    open={cashAccountDeleteOpen}
                    handleClose={handleCloseDeleteCashAccount}
                    handleSave={handleSaveCashAccount}
                    label={cashAccountName}
                />
                {/* ------------------------ Transaction Dialog -------------------------------------- */}
                <TransactionDialog
                    open={transactionOpen}
                    handleClose={handleCloseTransaction}
                    code={transactionCode}
                    setCode={setTransactionCode}
                    fund_id={transactionFund_id}
                    setFundID={setTransactionFundID}
                    fundsres={fundsres}
                    fund_type_id={transactionFund_type_id}
                    setFundTypeId={setTransactionFundTypeId}
                    date={transactionDate}
                    setDate={setTransactionDate}
                    fundtypesres={fundtypesres}
                    transaction_type_id={transactionTransaction_type_id}
                    setTransactionTypeId={setTransactionTransactionTypeId}
                    transactiontypesres={transactiontypesres}
                    description={transactionDescription}
                    setDescription={setTransactionDescription}
                    opening_balance={transactionOpening_balance}
                    handleChangeOpeningBalance={handleChangeOpeningBalanceTransaction}
                    amount={transactionAmount}
                    handleChangeAmount={handleChangeAmountTransaction}
                    closing_balance={transactionClosing_balance}
                    handleChangeClosingBalance={handleChangeClosingBalanceTransaction}
                    handleSave={handleSaveTransaction}
                    minimal={true}
                />
                <DeleteDialog
                    open={transactionDeleteOpen}
                    handleClose={handleCloseDeleteTransaction}
                    handleSave={handleSaveTransaction}
                    label={'the transaction'}
                />
            </MainCard>
        </>
    );
}
