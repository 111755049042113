import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    TextField
} from '@mui/material';
import React from 'react';

export default function ClientDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Client</DialogTitle>
            <DialogContent>
                <DialogContentText>Fill in the form to add/edit the client</DialogContentText>
                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            value={props.code}
                            onChange={(e) => props.setCode(e.target.value)}
                            margin="dense"
                            id="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.name}
                            onChange={(e) => props.setName(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Client Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            value={props.fee}
                            onChange={(e) => props.setFee(e.target.value)}
                            margin="dense"
                            id="fee"
                            label="Fee"
                            type="number"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
