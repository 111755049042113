import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React from 'react';

export default function CashAccountDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cash Account</DialogTitle>
            <DialogContent>
                <DialogContentText>Fill in the form to add/edit the cash account</DialogContentText>
                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            value={props.code}
                            onChange={(e) => props.setCode(e.target.value)}
                            margin="dense"
                            id="code"
                            label="Code"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.name}
                            onChange={(e) => props.setName(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Cash Account Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TextField margin="dense" id="client" label="Client" type="text" fullWidth variant="outlined" /> */}
                        <FormControl fullWidth disabled={props.disableClient ? props.disableClient : false}>
                            <InputLabel id="demo-simple-select-label">Client</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.client}
                                label="Client"
                                onChange={(e) => props.setClient(e.target.value)}
                            >
                                {props.clientsRes.map((item) => {
                                    return (
                                        <MenuItem key={`client-${item.code}`} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                            value={props.interest}
                            onChange={(e) => props.setInterest(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Interest"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
